const NotFound = () => {
    return (
    <main>
        <section class="notFound">
            <h3>404</h3>
            <h2>Page Not Found</h2>
            <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
        </section>
    </main>
    )
}

export default NotFound;