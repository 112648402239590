import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div id="fLeft">
                <a href="index.html" id="logo_footer">
                    <img src={require("../assets/Logo-small.png")} alt="GSEL Logo" />
                </a>
                <div id="copyright">
                    <span>Copyright © 2023 GreenStreak Energies Ltd.</span>
                </div>
            </div>
            <div id="fRight">
                <Link to="/about-us" className="navlink2">About Us</Link>
                <Link to="/products-and-services" className="navlink2">Products & Services</Link>
                <Link to="/contact-us" className="navlink2">Contact Us</Link>
            </div>
        </footer>
    )
}

export default Footer;