import { useEffect } from "react";
import DocumentMeta from "react-document-meta";

const About = ({setPage}) => {
    useEffect(() => {
        setPage("about")
    })

    const meta = {
        title: "About • GreenStreak",
        description: "Learn more about GreenStreak Energies and how we can meet all your energy needs",
        meta: {
            name: {
                keywords: "GreenStreak, Engineering, Consulting Energy, Optimization, Energy-as-a-Service, Renewable, Sustainability, Battery, Africa"
            }
        }
    }

    return (
        <DocumentMeta {...meta} >
            <main>
                <header id="about_head">
                    <h3>About Us</h3>
                    <h1>GreenStreak Energies Ltd.</h1>
                </header>
                <section className="section abt">
                    <article className="info">
                        <h2>Proudly Nigerian</h2>
                        <p>
                            GreenStreak Energies Limited {"(GSEL)"} is a Nigerian registered
                            engineering design, procurement, construction, installation, and
                            commissioning company headquartered in Lagos, Nigeria. We provide
                            conventional, renewable, and hybrid energy systems, Energy as a
                            Service packages, clean energy generation, and energy storage and
                            optimization systems. Additionally, GSEL offers operations management
                            and consulting services, as well as sustainability advisory services
                            across Africa
                        </p>
                    </article>
                    <img src={require("../assets/NG.jpg")} alt="Nigeria" />
                </section>
                <section className="section abt abt2">
                    <img src={require("../assets/Deal.jpg")} alt="Business" />
                    <article className="info">
                        <h2>Business</h2>
                        <p>
                            Our business strategy, organisational culture and day-to-day
                            operations are aligned to continually meet and deliver our
                            client's concept of value in a professional, safe, and
                            efficient manner.
                            <br/>
                            <br/>
                            We are a Company in a growth mode, always seeking strategic
                            partners with complementary competences to jointly provide
                            more value to our current and prospective clients
                        </p>
                    </article>
                </section>
                <section className="section abt abt3">
                    <article className="info">
                        <h2>Energy-as-a-Service</h2>
                        <p>
                            Our EAAS model provides a number of benefits for businesses,
                            including:
                        </p>
                        <ul>
                            <li>
                                Predictable energy costs - allows businesses to budget
                                for their energy costs more accurately, as they are paying
                                a fixed monthly fee rather than fluctuating energy prices
                            </li>
                            <li>
                                Convenience - removes the need for businesses to invest
                                in and maintain their own energy generation equipment, saving
                                them both time and money
                            </li>
                            <li>
                                Customized solutions - we work with clients to assess their
                                energy needs and develop a customized energy solution that meets
                                their specific requirements
                            </li>
                            <li>
                                Ongoing support - we provide all necessary equipment and maintenance,
                                as well as ongoing support to ensure that our clients are getting
                                the most out of their energy subscription
                            </li>
                        </ul>
                    </article>
                    <img src={require("../assets/Work.jpg")} alt="Engineer"/>
                </section>
            </main>
        </DocumentMeta>
    )
}

export default About;