import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";

const products = [
    {
        name: "Beat Crystal Control Technology",
        img1: require("../assets/PS/Beat1.png"),
        img2: require("../assets/PS/Beat2.png"),
        img3: require("../assets/PS/Beat3.png"),
        desc: "Product descriptions goes here"
    }
]

const services = [
    {
        name: "Battery Management Systems",
        img1: require("../assets/PS/BMS1.jpg"),
        img2: require("../assets/PS/BMS2.jpg"),
        img3: require("../assets/PS/BMS3.png"),
        desc: "Service descriptions goes here"
    }
]

const ProductService = ({setPage}) => {
    useEffect(() => {
        setPage("PS")
    })

    const meta = {
        title: "Products & Services • GreenStreak",
        description: "Explore the products and services that GreenStreak offers",
        meta: {
            name: {
                keywords: "Products, Services, Battery management, Energy Storage, BMS, Beat, WaveTech"
            }
        }
    }

    const [selected, setSelected] = useState(["none", 0])
    const [content, setContent] = useState()

    const changeContent = (x, y) => {
        setSelected([x, y])
        if (x === "product") {
                setContent(
                <div id="psContent" >
                    <h1>{products[y].name}</h1>
                    <div id="psPics">
                        <img src={products[y].img2} alt={products[y].name + "image" + 2} />
                        <img src={products[y].img3} alt={products[y].name + "image" + 3} />
                    </div>
                    <p>{products[y].desc}</p>
                </div>
                )
        } else {
            setContent(
            <div id="psContent" >
                <h1>{services[y].name}</h1>
                <div id="psPics">
                    <img src={services[y].img2} alt={services[y].name + "image" + 2} />
                    <img src={services[y].img3} alt={services[y].name + "image" + 3} />
                </div>
                <p>{services[y].desc}</p>
            </div>
            )
        }
    }

    return (
        <DocumentMeta {...meta} >
            <main id="PandS">
                <header>
                    <h1>Our Products and Services</h1>
                </header>
                <section id="psItems">
                    <section id="ps">
                        <div id="Products">
                            <h2>Products</h2>
                            {products.map((item, i) => (
                                <button key={i} id="prod" onClick={() => changeContent("product", i)}>
                                    <img className="psImg" src={item.img1} alt={item.name + " image " + i} />
                                    <h3>{item.name}</h3>
                                </button>
                            ))}
                        </div>
                        <div id="Services">
                            <h2>Services</h2>
                            {services.map((item, i) => (
                                <button key={i} id="serv" onClick={() => changeContent("service", i)}>
                                    <img className="psImg" src={item.img1} alt={item.name + " image " + i} />
                                    <h3>{item.name}</h3>
                                </button>
                            ))}
                        </div>
                    </section>
                    <section id="psDesc">
                        {selected[0] === "none" ? <h4 id="generic">Select an item to see details</h4>
                        : <div>{content}</div>}
                    </section>
                </section>
            </main>
        </DocumentMeta>
    )
}

export default ProductService;