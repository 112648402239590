import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"

const Nav = ({ page, setPage }) => {
    const [prevScroll, setPrevScroll] = useState(0)
    const [open, setOpen] = useState(false)
    const navRef = useRef(null);

    const handleScroll = () => {
        if (!open && page !== "contact") {
            if (window.scrollY > prevScroll && window.scrollY > 100) {
                navRef.current.style.transform="translateY(-95px)"
            } else {
                navRef.current.style.transform="translateY(0px)"
            }
            setPrevScroll(window.scrollY)
        }
    }

    useEffect(() => {
        if (page === "home") {
            if (window.scrollY > 50) {
                navRef.current.style.backgroundColor="rgb(255, 255, 255, 1)"
                navRef.current.style.boxShadow="0 0 4px rgb(128, 128, 128, 1)"
                document.querySelectorAll(".navlink")?.forEach(x => {
                    x.style.color="black"
                })
            } else {
                navRef.current.style.backgroundColor="rgb(255, 255, 255, 0)"
                navRef.current.style.boxShadow="0 0 4px rgb(128, 128, 128, 0)"
                document.querySelectorAll(".navlink")?.forEach(x => {
                    x.style.color="black"
                })
            }
        } else {
            navRef.current.style.backgroundColor="rgb(255, 255, 255, 1)"
            navRef.current.style.boxShadow="0 0 4px rgb(128, 128, 128, 1)"
            document.querySelectorAll(".navlink")?.forEach(x => {
                x.style.color="black"
            })
        }
    }, [page, prevScroll])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    const hamburger = document.querySelector(".hamburger");
    const menu = document.querySelector(".menu");
    const overlay = document.querySelector(".overlay");

    const activate = () => {
        hamburger?.classList.toggle("active");
        menu?.classList.toggle("active");
        overlay?.classList.toggle("active");
        setOpen(true)
    }

    document.querySelectorAll(".navlink")?.forEach(n => n.addEventListener("click", () => {
        hamburger?.classList.remove("active");
        menu?.classList.remove("active");
        overlay?.classList.remove("active");
        setOpen(false)
    }))

    const deactivate = () => {
        hamburger?.classList.remove("active");
        menu?.classList.remove("active");
        overlay?.classList.remove("active");
        setOpen(false)
    }

    return (
        <nav ref={navRef} className="navbar">
            <div className="Logo">
                <Link to="/">
                    <img src={require("../assets/Logo.png")} alt="Home" width="275" />
                </Link>
            </div>
            <div className="menu">
                <span className="navitem Home">
                    <Link to="/" className="navlink">Home</Link>
                </span>
                <span className="navitem">
                    <Link to="/about-us" className="navlink">About Us</Link>
                </span>
                <span className="navitem">
                    <Link to="/products-and-services" className="navlink">Products & Services</Link>
                </span>
                <span className="navitem">
                    <Link to="/contact-us" className="navlink">Contact Us</Link>
                </span>
            </div>
            <div className="hamburger" onClick={activate}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <div className="overlay" onClick={deactivate}></div>
        </nav>
    )
}

export default Nav;