import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Home from './components/Home';
import Footer from './components/Footer'
import About from './components/About';
import ProductService from './components/ProductService';
import Contact from './components/Contact';
import NotFound from './components/NotFound';

function App() {
  const [page, setPage] = useState("home")

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    // eslint-disable-next-line
    }, [page]);
    return null;
  }

  return (
    <Router>
      <ScrollToTop />
      <Nav page={page} setPage={setPage} />
      <Routes>
        <Route path="/" element={<Home setPage={setPage} />} />
        <Route path="/about-us" element={<About setPage={setPage} />} />
        <Route path="/products-and-services" element={<ProductService setPage={setPage} />} />
        <Route path="/contact-us" element={<Contact setPage={setPage} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App;
