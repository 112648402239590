import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faCaretLeft, faCaretRight, faGrip } from "@fortawesome/free-solid-svg-icons";

const pics = [
    {
        pic: require("../assets/Gallery/site2.jpg"),
        alt: "Instalations by our field team",
    },
    {
        pic: require("../assets/Gallery/site4.jpg"),
        alt: "Inspecting products prior to installation",
    },
    {
        pic: require("../assets/Gallery/Batteries1.jpg"),
        alt: "Battery configuration at one of our sites",
    },
    {
        pic: require("../assets/Gallery/Batteries2.jpg"),
        alt: "Battery status remote transmitter",
    },
    {
        pic: require("../assets/Gallery/data1.jpg"),
        alt: "Live locations",
    },
    {
        pic: require("../assets/Gallery/data2.jpg"),
        alt: "Battery monitor dashboard",
    },
    {
        pic: require("../assets/Gallery/data3.jpg"),
        alt: "Battery Voltage monitoring",
    },
    {
        pic: require("../assets/Gallery/data4.jpg"),
        alt: "Battery DC voltage monitoring",
    },
    {
        pic: require("../assets/Gallery/data5.jpg"),
        alt: "Battery current monitoring",
    },
]

const Gallery = () => {
    const [view, setView] = useState(false)
    const [cPic, setCPic] = useState(0)

    // Setting and resetting elements when window opens or closes
    useEffect(() => {
        if (view) {
            document.getElementById("window")?.classList.add("active")
            document.querySelector(".overlay2")?.classList.add("active")
        } else {
            document.getElementById("window")?.classList.remove("active")
            document.querySelector(".overlay2")?.classList.remove("active")
            grid(0)
        }
    }, [view])

    // Expand the selected picture
    const focPic = (x) => {
        setCPic(x)
        if (x > -1) {
            document.getElementById("picNext")?.classList.add("active")
            document.getElementById("picPrev")?.classList.add("active")
            document.getElementById("togGrid")?.classList.add("active")
            document.getElementById("obscure")?.classList.add("hide")
            document.getElementById("picContainer")?.classList.add("active")
            let num = -1
            document.querySelectorAll(".pics")?.forEach(q => {
                q.classList.remove("active")
                q.classList.remove("inactive")
                num += 1
                if (num !== x) {
                    console.log(x)
                    q.classList.add("inactive")
                }
            })
            document.querySelector(`.pic${x}`)?.classList.add("active")
        }
    }

    const grid = (r) => {
        document.getElementById("obscure")?.classList.remove("hide")
        document.getElementById("picNext")?.classList.remove("active")
        document.getElementById("picPrev")?.classList.remove("active")
        document.getElementById("togGrid")?.classList.remove("active")
        if (r === 1) {
            document.querySelectorAll(".pics")?.forEach(q => {
                q.classList.remove("active")
                q.classList.remove("inactive")
            })
            document.getElementById("picContainer")?.classList.remove("active")
        } else {
            setTimeout(() => {
                document.querySelectorAll(".pics")?.forEach(q => {
                    q.classList.remove("active")
                    q.classList.remove("inactive")
                })
                document.getElementById("picContainer")?.classList.remove("active")
            }, 500);
        }
    }

    // Open the gallery window
    const bigView = (y) => {
        setView(true)
        focPic(y)
    }

    // Cycle through the pictures
    const changePic = (z) => {
        if (z === "+") {
            focPic(cPic + 1)
        } else {
            focPic(cPic - 1)
        }
    }

    // Disable arrows if at the beginning or end and change captions
    useEffect(() => {
        if (cPic === pics.length - 1) {
            document.getElementById("picNext").classList.add("no")
        } else if (cPic === 0) {
            document.getElementById("picPrev").classList.add("no")
        } else {
            document.getElementById("picNext").classList.remove("no")
            document.getElementById("picPrev").classList.remove("no")
        }
        if (cPic > -1) {
            document.getElementById("capCon").textContent = pics[cPic].alt
        }
    }, [cPic])

    return (
        <>
            <section className="section gallery">
                <h2 className="centred above">Gallery</h2>
                <div id="gal">
                    {pics.map((item, i) => (
                        <img
                          key={i}
                          src={item.pic}
                          alt={item.alt}
                          className="peek"
                          onClick={() => bigView(i)}
                        />
                    ))}
                </div>
                <button className="btn VA" onClick={() => bigView(-1)}>View All</button>
                <div className="slant2">
                    <div id="aSlant"></div>
                </div>
            </section>
            <div id="window">
                <h2 className="centred gTitle">Gallery</h2>
                <button id="togGrid" onClick={() => grid(1)}>
                    <FontAwesomeIcon icon={faGrip} />
                </button>
                <button id="picPrev" onClick={() => (changePic("-"))}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                </button>
                <div id="picContainer">
                    {pics.map((item, i) => (
                        <img
                            key={i}
                            src={item.pic}
                            alt={item.alt}
                            className={`grid pics pic${i}`}
                            onClick={() => bigView(i)}
                        />
                    ))}
                    <div id="capt">
                        <h3 id="capCon"> </h3>
                    </div>
                </div>
                <button id="picNext" onClick={() => (changePic("+"))}>
                    <FontAwesomeIcon icon={faCaretRight} />
                </button>
                <div id="obscure"></div>
            </div>
            <button className="overlay2" onClick={() => setView(false)}>
                <span><FontAwesomeIcon icon={faX} /></span>
            </button>
        </>
    )
}

export default Gallery;