import { useEffect } from "react";
import DocumentMeta from "react-document-meta";

const Contact = ({setPage}) => {
    useEffect(() => {
        setPage("contact")
    })

    const meta = {
        title: "Contact • GreenStreak",
        description: "Get in touch with Greenstreak Energies to find out if our solutions are right for you",
        meta: {
            name: {
                keywords: "Contact, Office, Phone, Email, Location"
            }
        }
    }

    return (
        <DocumentMeta {...meta} >
            <main>
                <header id="contact_head">
                    <h3>Contact</h3>
                    <h1>Get in Touch with GreenStreak</h1>
                </header>
                <section className="locations">
                    <article id="text">
                        <h2>Our Office</h2>
                        <ul>
                            <li id="company"><b>GreenStreak Energies Ltd.</b></li>
                            <li>Plot 5 Ibadan Street</li>
                            <li>Osborne Foreshore Estate</li>
                            <li>Ikoyi, Lagos</li>
                            <li>Nigeria</li>
                        </ul>
                        <div id="cIcons">
                            <a href="mailto: contact@greenstreakenergies.com" target="_blank" rel="noreferrer" id="email">
                                <img src={require("../assets/Env.png")} alt="Email" width="60px" height="60px"/>
                                <p>contact@greenstreakenergies.com</p>
                            </a>
                            <div id="phone">
                                <img src={require("../assets/Phone.png")} alt="Phone" width="45px" height="45px"/>
                                <p>+234 (0) 905 911 7777</p>
                            </div>
                        </div>
                    </article>
                    <div>
                        <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1849.477754928785!2d3.4253512680735914!3d6.46550199963933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf4b6ca5ec0bf%3A0xdd84f90813a170bb!2s5%20Ibadan%20St%2C%20Ikoyi%20106104%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sca!4v1675020999844!5m2!1sen!2sca" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </section>
            </main>
        </DocumentMeta>
    )
}

export default Contact;