import { useEffect } from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Gallery from "./Gallery";


const Home = ({setPage}) => {
    useEffect(() => {
        setPage("home")
    })

    const meta = {
        title: "Home • GreenStreak",
        description: "Welcome to GreenStreak Energies - Your Energy-as-a-Service Solution",
        meta: {
            name: {
                keywords: "GreenStreak, Energy, Energy-as-a-Service, Renewable, Sustainability, Battery, Africa"
            }
        }
    }

    return (
        <DocumentMeta {...meta} >
            <main>
                <section>
                    <header id="banner">
                        <article id="blry">
                            <h1>Your Energy-as-a-Service Solution</h1>
                            <p id="overview">
                                Our Energy-as-a-Service (EaaS) model offers a simple,
                                cost-effective solution. With GSEL, you can pay for your
                                energy usage on a monthly subscription basis, leaving you
                                free to focus on what you do best - running your business.
                            </p>
                        </article>
                        <div className="slant">
                            <div id="bSlant"></div>
                        </div>
                    </header>
                </section>
                <section className="section idx" id="about">
                    <article id="abtInfo">
                        <h2>About GreenStreak Energies</h2>
                        <p>
                            GreenStreak Energies Limited is a Nigerian registered Engineering Design, Procurement, Construction, Installation
                            and Commissioning Company providing conventional, renewable and hybrid energy systems, Energy as a Service,
                            energy storage and optimization systems, clean energy generation and operations management, products and
                            services and sustainability advisory services across Africa.
                        </p>
                        <div className="slant2">
                            <div id="aSlant"></div>
                        </div>
                        <button id="learn_more">
                            <Link to="/about-us" href="about.html">
                                <span className="btn">Learn More</span>
                            </Link>
                        </button>
                    </article>
                    <img src={require("../assets/Gallery/site1.jpg")} alt="GreenStreak" />
                </section>
                <section className="section" id="products_and_services">
                    <article>
                        <h2>Our Product and Service Offerings</h2>
                        <p>
                            GreenStreak has a variety of product and service offerings to help
                            you with your energy needs. Our offerings include:
                        </p>
                    </article>
                    <button className="cols">
                        <Link to="products-and-services">
                            <div className="psIcons">
                                <div>
                                    <img src={require("../assets/Icons/Renewable.png")} alt="Renewable Energy EPCIC" />
                                    <h4>Renewable Energy EPCIC</h4>
                                </div>
                                <div>
                                    <img src={require("../assets/Icons/turbine.png")} alt="Gas Turbines" />
                                    <h4>Energy and Power Systems (Turbines and Generators)</h4>
                                </div>
                                <div>
                                    <img src={require("../assets/Icons/ESG.png")} alt="ESG" />
                                    <h4>ESG and Sustainability Advisory Services</h4>
                                </div>
                                <div>
                                    <img src={require("../assets/Icons/Battery.png")} alt="Energy Storage" />
                                    <h4>Battery Services and Energy Storage Solutions</h4>
                                </div>
                                <div>
                                    <img src={require("../assets/Icons/PM.png")} alt="Project Management" />
                                    <h4>Project Management</h4>
                                </div>
                            </div>
                        </Link>
                    </button>
                    <div className="slant">
                        <div id="bSlant"></div>
                    </div>
                </section>
                <Gallery />
                <section className="section idx" id="partners">
                    <article>
                        <h2 className="centred">Our Partner</h2>
                        <div>
                            <div className="ptnr">
                                <a href="https://www.wavetech.com/" target="_blank" rel="noreferrer" className="partner">
                                    <img src={require("../assets/Wavetech.png")} alt="WaveTech GmbH Logo"/>
                                    <h4>WaveTech GmbH</h4>
                                </a>
                            </div>
                        </div>
                    </article>
                </section>
            </main>
        </DocumentMeta>
    )
}

export default Home;